import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import accountingv1 from "../../Assets/Projects/accountingv1.png";
import accountingv2 from "../../Assets/Projects/accountingv2.png";
import expertbook from "../../Assets/Projects/expertbook.png";
import gigparcel from "../../Assets/Projects/gigparcel.jpg";
import chatmingle from "../../Assets/Projects/chatmingle.jpg";
import extension from "../../Assets/Projects/extension.png";
import notezipper from "../../Assets/Projects/notezipper.jpg";
import jobexplorer from "../../Assets/Projects/jobexplorer.jpg";
import photopicker from "../../Assets/Projects/photopicker.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Client <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked with client.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={accountingv1}
              isPrivateProject={true}
              title="Web Based Accounting Software  (v1.1.0) "
              description="I designed and managed various iterations of a customized Web-Based Accounting Application for a client, integrating HTML, CSS, JavaScript, and PHP to align with their specific needs. 
              The application boasted comprehensive functionalities for financial monitoring, invoicing, and generating reports, ensuring robust performance. Over time, 
              I delivered continuous maintenance and support to adapt to the evolving requirements of the client. Eventually, the initial version was phased out, making way for the introduction of Version 2.0.0, tailored to further enhance user experience and efficiency."
              ghLink="#"
              videoLink="https://www.youtube.com/watch?v=kvsifhz3-kc"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={accountingv2}
              isPrivateProject={true}
              title={`Web Based Accounting Software (v2.0.0)`}
              description="I upgraded our client's custom Web-Based Accounting Application to Version 2.0.0, making it better in every way. It now works faster, has more useful features, and looks nicer too! With improved efficiency and a sleeker interface, users can manage finances, create invoices, and generate reports with ease. We've kept it user-friendly while packing in more functionality, ensuring our client's accounting tasks are handled smoothly and efficiently."
              ghLink="#"
              videoLink="https://youtu.be/DINI7-ImSXA"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={expertbook}
              isPrivateProject={true}
              title="Expert Book Mart"
              description="In this project,  Expert Book Mart, I teamed up with a friend, and together, we worked closely with the client to ensure their vision was realized. It was a fantastic opportunity to learn and grow while collaborating in a team setting. We utilized tools like Jira to streamline our workflow, enhancing our efficiency and organization throughout the project. Our focus was on delivering a tailor-made solution that perfectly matched the client's needs. We dedicated ourselves to designing and implementing an intuitive user interface for browsing, searching, and purchasing books, ensuring a seamless experience for customers."
              ghLink="#"
              demoLink="https://www.expertbookmart.com/"
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <h1 className="project-heading">
          Hobby <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gigparcel}
              isPrivateProject={true}
              title=" GigParcel - Cross Platform Parcel Delivery App"
              description="For my final year project, I embarked on creating GigParcel, a Cross-Platform Parcel Delivery App using React Native. This innovative application aimed to simplify parcel delivery by connecting users directly with nearby gig workers. Throughout the development process, I focused on crafting a user-friendly platform that seamlessly facilitated parcel delivery operations. During the final year project evaluation, I confidently presented and defended GigParcel, showcasing not only my academic achievements but also my technical prowess. It stood as a testament to my dedication and skills, marking a significant milestone in my academic journey."
              ghLink="#"
              videoLink="https://www.youtube.com/watch?v=kvsifhz3-kc"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatmingle}
              isPrivateProject={false}
              title={`ChatMingle - Web Based Chat using MERN stack `}
              description="ChatMingle is a chat application  developed from scratch. Leveraging the powerful MERN stack, I implemented real-time messaging functionality using Socket.io, allowing users to engage in seamless conversations. With a focus on security, I integrated robust user authentication measures to ensure privacy and peace of mind for every user. Crafting a sleek and intuitive user interface was essential to provide an enjoyable chatting experience. Through this project, I honed my skills in socket-based communication, MERN stack development, and user authentication, solidifying my understanding of these concepts in practical application."
              ghLink="https://github.com/Bibker/ChatMingle"
            // videoLink="https://www.youtube.com/watch?v=kvsifhz3-kc"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={extension}
              isPrivateProject={false}
              title="Customer Finder Chrome Extension"
              description="Customer Finder Chrome Extension, a project I developed to explore the capabilities of Chromium Extensions. Utilizing Manifest V2, this extension is designed to search for customer PAN details from the IRD (Nepal's Tax Payer Portal), catering to personal business applications. Created for educational purposes, this extension showcases my study into the realm of Chromium Extensions and serves as a practical exercise in manifesting my understanding of web technologies. It's important to note that this extension is not intended for commercial use but rather as a demonstration of my learning journey and exploration of web development tools."
              ghLink="https://github.com/Bibker/extension-find-customer"
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={notezipper}
              isPrivateProject={false}
              title="Note App using MERN stack"
              description="Note Zipper is a project that I've personally developed and created using the full-stack MERN (MongoDB, Express, React, Node.js) technology stack. Through building this application, I've dived deep into the intricacies of web development, honing my skills in both front-end and back-end technologies. With Note Zipper, I've not only learned how to implement user authentication for secure account management but also how to design intuitive interfaces for seamless note creation, editing, and deletion. Additionally, I've explored the process of allowing users to customize their profiles with profile pictures, adding a personal touch to their experience. Note Zipper stands as a testament to my growth and proficiency in web development, showcasing my ability to create functional and user-centric applications."
              demoLink="https://notezipper.bibakbhusal.com.np/"
              ghLink="https://github.com/Bibker/Note-App"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={jobexplorer}
              isPrivateProject={false}
              title="Job Explorer using React Native"
              description="Job Explorer is a simple yet powerful app I've developed using React Native. It's designed to work seamlessly across various platforms like web browsers, Android devices, and iPhones. One of the coolest features is its ability to show real-time job updates, thanks to the JSearch API integration. Throughout the development process, I've learned a lot about creating apps that run smoothly on different devices and making them accessible to a wider audience. Job Explorer is not just about finding jobs; it's about making the job-hunting experience easier and more enjoyable for everyone."
              ghLink="https://github.com/Bibker/react-native-jobs"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={photopicker}
              isPrivateProject={false}
              title="Camera Gallery using Ionic  & Capacitor"
              description="Through my hobby project, the Photo Picker app, I've embarked on an enlightening journey to understand how Ionic and Capacitor collaborate in cross-platform app development. By leveraging Ionic's robust framework and Capacitor's native runtime, I've gained a deeper understanding of creating seamless applications across web browsers, Android, and iOS devices. As I delved into the intricacies of Photo Picker's development, I discovered the nuances of Ionic's user interface components and Capacitor's powerful capabilities for accessing native device features. This hands-on experience has not only enhanced my technical skills but also broadened my horizons in cross-platform app development. Through Photo Picker, I've unlocked the potential of Ionic and Capacitor, equipping myself with the knowledge and expertise to tackle future projects with confidence and proficiency."
              ghLink="https://github.com/Bibker/ionic-camera-gallery"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite, CgYoutube } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";

function ProjectCards(props) {
  const [expanded, setExpanded] = useState(false);
  const maxLength = 400;

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const renderDescription = () => {
    if (props.description.length <= maxLength) {
      return props.description;
    } else {
      return expanded ? props.description : props.description.slice(0, maxLength) + '...';
    }
  };


  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {renderDescription()}
          {props.description.length > maxLength && (
            <button onClick={toggleExpanded}>
              {expanded ? 'See less' : 'See more'}
            </button>
          )}
        </Card.Text>
        {
          !props.isPrivateProject ? (
            <Button variant="primary" href={props.ghLink} target="_blank">
              <BsGithub /> &nbsp;
              GitHub
            </Button>
          ) : (<></>)
        }

        {"\n"}
        {"\n"}

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

        {props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite /> &nbsp;
            {"Live Demo"}
          </Button>
        )}
        {props.videoLink &&
          (
            <Button
              variant="primary"
              href={props.videoLink}
              target="_blank"
              style={{ marginLeft: "10px" }}
            >
              <CgYoutube /> &nbsp;
              {"Video Demo"}
            </Button>
          )
        }
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
